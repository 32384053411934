<template>
  <section>
    <div class="content-header">
      <h2>About privilege</h2>
    </div>
    <div class="content-wrapper">
      <p>Privilege is the unearned social, political, economic and psychological benefits of membership in a group that has institutional and structural power.</p>
      <p>There are many types of privilege that different groups have in Canada. We commonly hear about privilege because of race or gender, but privilege also exists for different groups based on religion, sexuality, ability, class and education level.</p>
      <p>What types of privilege can you think of?  </p>
      <p class="lm">Learn more</p>
      <ul>
        <li>See how one professor used a recycling bin and scrap paper to teach students about privilege in <a href="https://www.youtube.com/watch?v=2KlmvmuxzYE" target="_blank">this BuzzFeed video</a>.</li>
        <li>Watch a <a href="https://www.youtube.com/watch?v=4liF-zuwGxE" target="_blank">classic animated video</a> that uses role reversal to get people thinking about accessibility.  </li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
